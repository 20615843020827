import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { Button } from '@3pp/ui-library'
import { currencyFormatter, eventTracker } from '@3pp/utils'
import styles from './styles.module.scss'

const ContributeNominal = ({
  amount,
  onValueChange,
}) => {
  const nominalOptions = [
    {
      value:10000,
      active: amount === 10000,
    },
    {
      value:20000,
      active: amount === 20000,
    },
    {
      value:50000,
      active: amount === 50000,
    },
    {
      value:100000,
      active: amount === 100000,
    },
  ]
  return (
    <>
      <div className={styles.section}>
        <span className={styles.donationLabel}>Donasi mulai dari Rp1.000</span>
      </div>
      <div className={styles.section}>
        <div className={styles.donationInput}>
          <span className={styles.currency}>Rp</span>
          <NumberFormat
            placeholder="0"
            value={amount < 1 ? '' : amount}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            isNumericString={true}
            onValueChange={onValueChange}
            isAllowed={(values) => {
              if (values.floatValue > 500000000) return false
              return true
            }}
          />
          {
            (amount % 1000 !== 0) &&
            <span className={styles.donationInputError}>Jumlah donasi harus dalam kelipatan ribuan</span>
          }
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.nominalOptions}>
          {nominalOptions.map(item => {
            return (
              <Button
                backgroundColor={item.active ? "#00AEEF" : "#fff"}
                color={item.active ? "#fff" : "#3a3a3a"}
                style={{
                  fontSize: 16,
                  flexGrow: 1,
                  borderRadius: 4,
                  boxShadow: "0 1px 3px 0 rgba(0,0,0,0.23)"
                }}
                margin="5px"
                width="45%"
                height="50px"
                key={item.value}
                onClick={() => {
                  eventTracker(`denom ${item.value} contribute gogive`)
                  onValueChange({floatValue: item.value})
                }}
                label={`Rp${currencyFormatter(item.value)}`}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

ContributeNominal.propTypes = {
  amount: PropTypes.number,
  isAllowed: PropTypes.func,
  onValueChange: PropTypes.func
}
export default ContributeNominal
