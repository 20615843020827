import { useState, useEffect } from 'react'

export const useFlashMessage = initialState => {
  const [errState, setErrState] = useState(initialState)

  useEffect(() => {
    let timeOut
    if (errState) {
      timeOut = setTimeout(() => {
        setErrState(false)
      }, 3000)
    }

    return () => clearTimeout(timeOut)
  }, [errState])

  return [errState, setErrState]
}
