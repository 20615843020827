import React from 'react'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'

import styles from '../styles.module.scss';

const Unlink = (props) => {
  return (
    <div>
      <ReactModal
        isOpen={props.unlinkModal}
        contentLabel="onRequestClose Example"
        onRequestClose={props.handleUnlink}
        className="Modal-unlink"
        overlayClassName="Overlay-unlink"
        ariaHideApp={false}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          size="lg"
          color="#3a3a3a"
          onClick={props.handleUnlink}
          style={{
            float: "right",
          }}
        />
        <div>
          <img
            src="/uploads/gopay-animate-link.svg"
            alt="gopay-link-animate"
            width="100%"
          />
        </div>
        <div className={styles.unlink}>
          <p className={styles[`unlink-title`]}>
            Unlink Gogive with GoPay
          </p>
          <button
            className={styles[`unlink-btn`]}
            onClick={props.submitUnlink}
          >
            Unlink
          </button>
        </div>
      </ReactModal>
    </div>
  )
}

export default Unlink
