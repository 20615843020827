import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import Cookie from 'universal-cookie'
import queryString from 'query-string'
import { stringify } from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'

import { fetchData, isEmpty } from '@3pp/utils'
import customHeaders from 'utils/customHeaders'
import { expirationDate } from 'utils/computeAssignmentForVisitorId'

import LoaderImg from '../assets/loader.gif'

import {
  Button,
} from '@3pp/ui-library';
import styles from './styles.module.scss'

const expiredCookies = {
  path: '/',
  expires: expirationDate(365)
}

const GopayLink = (props) => {
  const [isModal, setModal] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [phone, setPhone] = useState('')
  const cookies = new Cookie();
  const userCookies = cookies.get('usr')
  const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

  const urlParams = queryString.parse(isAndroid ? decodeURIComponent(props.location.search) : props.location.search)
  const stringifiedQuery = (!isEmpty(urlParams)) ? `?${stringify(urlParams)}` : ''

  useEffect(() => {
    if (userCookies) {
      setPhone(userCookies.phone.split('-')[1])
    }
  }, [setPhone, userCookies])

  const handleOpenModal = () => setModal(!isModal)

  const handleInput = (event) => {
    const phoneNumber = event.target.value

    if(phoneNumber.charAt(0) === '0'){
      return setPhone(phoneNumber.substr(1))
    }
    return setPhone(phoneNumber)
  }

  const isValid = phone.length >= 7 && phone.length <= 14

  const handleSubmit = async () => {
    const redirectCallback =
      urlParams.ref === 'contribute'
        ? `${process.env.REACT_APP_GOGIVE_URL}/campaign/${urlParams.campaign}/${urlParams.ref}`
        : process.env.REACT_APP_GOGIVE_URL

    setFormLoading(true)
    const submittedData = await fetchData({
      url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/gojek/account`,
      method: 'POST',
      body: {
        phone: `0${phone}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        redirect_callback: isAndroid
          ? `gojek://gocore/third_party_web?url=${encodeURIComponent(`${redirectCallback}${stringifiedQuery}`)}`
          : `${redirectCallback}${stringifiedQuery}`
      },
      additionalHeaders: customHeaders,
    })
    if (submittedData.data) {
      setFormLoading(false)
      cookies.set('gopay_id', submittedData.data[0].account_id, expiredCookies)

      if(submittedData.data[0]['activation_link_app'] && submittedData.data[0]['account_status'] !== "ENABLED") {
        return window.location = submittedData.data[0]['activation_link_app']
      }
      return props.history.push(urlParams.ref ? `/campaign/${urlParams.campaign}/${urlParams.ref}` : '/')
    }

    setFormLoading(false)
    setModal(false)
    setPhone('')
  }

  return (
    <>
      <ReactModal
        isOpen={isModal}
        contentLabel="onRequestClose Example"
        className="Modal-gopayLink"
        overlayClassName="Overlay-gopayLink"
        ariaHideApp={false}
      >
        <header className={styles[`verification__header`]}>
          <FontAwesomeIcon
            icon={faTimes}
            size="sm"
            color="#3a3a3a"
            onClick={handleOpenModal}
          />
        </header>
        <div className={styles[`verification__content`]}>
          <img
            src="/uploads/gopay-verification.png"
            alt="gopay-verification"
            width="57"
          />
          <h2 className={styles[`verification__content-title`]}>
            Link Gogive with GoPay
          </h2>
          <span>
            Setelah kamu terhubung, kamu bisa menggunakan GoPay untuk:
          </span>
          <div className={styles[`verification__content-info`]}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="#50B058"
              size="sm"
            />
            <span className={styles[`verification__content-info-text`]}>
              Membayar donasi di Gogive
            </span>
          </div>
          <hr className={styles[`verification__separate`]}/>
          <span>
            Kamu akan menghubungkan dengan akun Gojek kamu nomor ini:
          </span>
          <div className={styles[`verification__phone`]}>
            <FontAwesomeIcon
              icon={faPhoneAlt}
              size="lg"
            />
            <span className={styles[`verification__phone-number`]}>
              +62 {phone}
            </span>
          </div>
          <span>
            Untuk melanjutkan penghubungan, masukkan PIN GoPay kamu.
          </span>
        </div>
        <div className={styles[`gopayLink__bottom-fix`]}>
          <Button
            backgroundColor="#50B058"
            disabled={!isValid}
            height="40px"
            fontSize="14px"
            margin="25px 16px"
            onClick={() => isValid && handleSubmit()}
          >
            {
              formLoading ?
                <span><img src={LoaderImg} alt="Submitting Data" /></span>
                : <span>Continue</span>
            }
          </Button>
        </div>
      </ReactModal>

      <section className={styles.gopayLink}>
        <div className={styles[`gopayLink__top`]}>
          <img
            src="/uploads/gopay-animate-link.svg"
            alt="gopay-link-animate"/>
          <h2 className={styles[`gopayLink__top-title`]}>
          Hubungkan GoPay
          </h2>
          <span className={styles[`gopayLink__top-subTitle`]}>
          Setelah terhubung, kamu bisa bayar donasi di GoGive pakai GoPay.
          </span>
        </div>
        <div className={styles[`gopayLink__bottom`]}>
          <span className={styles[`gopayLink__bottom-text`]}>
          Kami akan mengirim kode verifikasi ke no. HP yang kamu masukkan di sini.
          </span>
          <div className={styles[`gopayLink__bottom-number`]}>
            <div className={styles[`gopayLink__bottom-number__left`]}>
              <img
                src="/uploads/id-flag.png"
                alt=""
                className={styles[`gopayLink__bottom-number__left-flag`]}
              />
              <span>
              +62
              </span>
            </div>
            <div className={styles[`gopayLink__bottom-cursor`]}>
              <input
                type="tel"
                placeholder="No. HP terdaftar GoPay"
                className={styles[`gopayLink__bottom-form`]}
                defaultValue={phone}
                onChange={handleInput}
              />
              <i></i>
            </div>
          </div>
        </div>
        <div className={styles[`gopayLink__bottom-code`]}>
          <Button
            disabled={!isValid}
            backgroundColor="#50B058"
            height="40px"
            fontSize="14px"
            margin="25px 16px"
            onClick={() => {
              setModal(true)
            }}
          >
            <span>Dapatkan kode verifikasi</span>
          </Button>
        </div>
      </section>
    </>
  )
}


export default GopayLink
