import React from 'react'
import ExplorePage from 'components/ExplorePage'

const Explore = (props) => {
  return (
    <ExplorePage {...props} />
  )
}

export default Explore
