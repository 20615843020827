import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import TagManager from 'react-gtm-module'
import * as Sentry from "@sentry/react";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs)
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
