import React from 'react';
import { currencyFormatter } from '@3pp/utils'
import ImgFailed from '../assets/donation-failed.svg'
import styles from '../styles.module.scss';

const Failed = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles['wrapper-img']}>
        <img src={ImgFailed} alt='failed' />
      </div>
      <p className={styles.textTitle}>Pembayaran Belum Berhasil, Silahkan <br/> Coba Sekali Lagi</p>
      <p className={styles.textSubtitle}>Donasi Sebesar</p>
      <span className={styles.textAmount}>Rp{currencyFormatter(props.amount)}</span>
      <p className={styles.textRefund}>Jika donasimu belum berhasil &amp; saldo GoPaymu sudah terpotong, dana akan dikembalikan ke GoPaymu H+3</p>

      <div className={styles.customDivider}> </div>
    </div>
  );
}

export default Failed
