/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import queryString, { stringify } from 'query-string'

import Cookies from 'universal-cookie'

import {
  Button,
  SwitchToggle,
  Textarea,
  FlashMessage
} from '@3pp/ui-library'
import { fetchData, currencyFormatter, isEmpty } from '@3pp/utils'
import { eventTracker } from '@3pp/utils'
import { useFlashMessage } from 'utils/useFlashMessage'
import { expirationDate, setOpenCodeCookie } from 'utils/computeAssignmentForVisitorId'
import customHeaders from 'utils/customHeaders'
import { getAppInfo, getUserInfo, isGojekDevtools } from 'utils/gojekSdk'
import Unlink from 'components/Gopay/Account/Unlink'
import Divider from 'components/common/Divider'

import LoaderImg from './assets/loader.gif'
import styles from './styles.module.scss'
import ContributeNominal from './ContributeNominal'
import PopupConnectSSO from './PopupConnectSSO'


const ContributePage = props => {
  const cookies = new Cookies()
  const [formLoading, setFormLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [donationFailed, setDonationFailed] = useFlashMessage(false)
  const [anonym, setAnonym] = useState(false)
  const [name, setName] = useState('')
  const [email_or_phone, setEmailOrPhone] = useState('')
  const [useComment, setUseComment] = useState(false)
  const [comment, setComment] = useState('')
  const [amount, setAmount] = useState(0)
  const [campaign, setCampaign] = useState(null)
  const [loading, setLoading] = useState(true)
  const [gopayLoading, setGopayloading] = useState(false)
  const [balance, setBalance] = useState('')
  const [unlinkModal, setUnlinkModal] = useState(false)
  const [isLinkedGopay, setIsLinkedGopay] = useState(cookies.get('gopay_id'))
  const [gopayStatus, setGopayStatus] = useState(cookies.get('gopay_status'))

  const userCookies = cookies.get('usr')
  const isUsingGopayAccount = gopayStatus === 'ENABLED'
  const isSso = cookies.get('usr') ? true : false
  const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1
  const clearQParams = !!props.location.search ? [...new Set(props.location.search.split('&'))].join('&') : props.location.search
  const urlParams = queryString.parse(isAndroid ? decodeURIComponent(clearQParams) : clearQParams)

  const submitUnlink = async () => {
    eventTracker('unlink gopay tokenisation contribute')
    await fetchData({
      url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/gojek/account/${isLinkedGopay}/unlink`,
      method: 'POST',
      additionalHeaders: customHeaders,
    })

    cookies.remove('gopay_id', { path: '/' })
    cookies.remove('gopay_phone', { path: '/' })
    cookies.remove('gopay_status', { path: '/' })

    setGopayStatus(undefined)
    setIsLinkedGopay(undefined)
    setUnlinkModal(false)
  }

  useEffect(() => {
    if (isSso) {
      setName(userCookies.name)
      setEmailOrPhone(userCookies.phone.replace(/[^0-9]/g, ''))
    }

    if(isLinkedGopay) {
      setGopayloading(true)
      const fetchGopayAccount = async () => {
        const { data } = await fetchData({
          url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/gojek/account/${isLinkedGopay}`,
          method: 'GET',
          additionalHeaders: customHeaders,
        })
        if(data[0].account_status !== 'ENABLED') {
          setGopayloading(false)
          return submitUnlink()
        }
        cookies.set('gopay_status', data[0].account_status, {
          path: '/',
          expires: expirationDate(365)
        })
        cookies.set('gopay_phone', data[0].phone, {
          path: '/',
          expires: expirationDate(365)
        })
        setGopayStatus(data[0].account_status)
        setEmailOrPhone(data[0].phone)
        setBalance(data[0].balance)
        setGopayloading(false)
      }
      fetchGopayAccount()
    }

    const fetchCampaign = async () => {
      const campaignData = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/campaign/${props.match.params.shortUrl}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      })
      setCampaign(campaignData.data[0])
      setLoading(false)
    }

    const sendEventSdk = () => {
      isGojekDevtools && window.gojek.toggleOnDevTool();
      const eventName = 'Payment Page Viewed'
      const eventValue = {
        'Url': `${process.env.REACT_APP_GOGIVE_URL}/campaign/${props.match.params.shortUrl}/contribute?hashed_customerid`,
        'Source': `utm_source=3pp_gojek`,
        'Medium': `utm_medium=${urlParams.utm_medium}`,
        'Campaign': `utm_campaign=${urlParams.utm_campaign || props.match.params.shortUrl}`,
        'PartnerName': 'Kitabisa',
        'ProductID': props.match.params.shortUrl,
        'ProductName': urlParams.category,
        'SSO': isSso,
      }
      isGojekDevtools && window.gojek.console.log(
        `window.gojek.sendEvent(${eventName}, ${JSON.stringify(eventValue)})`
      );
      window.gojek.sendEvent(eventName, eventValue)
    }
    try {
      sendEventSdk();
      getAppInfo();
      getUserInfo();
    } catch (error) {
      console.warn('Unsupported OS for gogive sdk when Payment Page Viewed: ', error);
    }
    fetchCampaign()
    //eslint-disable-next-line
  }, [])

  const sendClickEventSdk = () => {
    const eventName = 'Payment Button Clicked'
    const eventValue = {
      'url': `${process.env.REACT_APP_GOGIVE_URL}/campaign/${props.match.params.shortUrl}/contribute?hashed_customerid`,
      'Source': `utm_source=3pp_gojek`,
      'Medium': `utm_medium=${urlParams.utm_medium}`,
      'Campaign': `utm_campaign=${urlParams.utm_campaign || props.match.params.shortUrl}`,
      'PartnerName': 'Kitabisa',
      'Deeplink': 'gogive.kitabisa.com/campaign',
      'PaymentMethod': 'gopay',
      'ProductID': props.match.params.shortUrl,
      'ProductName': urlParams.category,
      'ActivityName': 'lanjutkan pembayaran',
      'SSO': isSso
    }
    try {
      isGojekDevtools && window.gojek.console.log(
        `window.gojek.sendEvent(${eventName}, ${JSON.stringify(eventValue)})`
      );
      window.gojek.sendEvent(eventName, eventValue)
    } catch (error) {
      console.warn('Unsupported OS for gogive sdk when Payment Button Clicked: ', error);
    }
  }

  const isValid = () => {
    return !!(amount && name && email_or_phone && isSso)
  }


  const submitData = () => {
    const utmSource = urlParams.utm_source || '3pp_gogive';
    const utmMedium = urlParams.utm_medium || null;
    const utmCampaign = urlParams.utm_campaign || props.match.params.shortUrl;
    const utmContent = urlParams.utm_content ? urlParams.utm_content.replace(/{gojek:|}/g,'') : urlParams.utm_content || null;

    const redirectCallback = `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}&utm_campaign=${utmCampaign}&category=${urlParams.category}`
    const redirect_callback_params = isAndroid ? decodeURIComponent(redirectCallback) : redirectCallback
      const generalData = {
      name,
      email_or_phone,
      payment_methods_id: isLinkedGopay ? Number(process.env.REACT_APP_GOPAY_TOKEN_ID): 20,
      platform: isSso ? 'gojek' : 'pwa',
      send_notification: true,
      redirect_callback: `${process.env.REACT_APP_GOGIVE_URL}/donation/status/`,
      redirect_callback_params,
    }
    const campaignSpecificData = {
      campaign_id: campaign.id,
      amount: Number(amount),
      comment: comment,
      campaigner_relation: false,
      is_anonymous: anonym,
      utm: {
        source: Array.isArray(utmSource) ? utmSource[0] : utmSource,
        medium: Array.isArray(utmMedium) ? utmMedium[0] : utmMedium,
        campaign: Array.isArray(utmCampaign) ? utmCampaign[0] : utmCampaign,
        content: Array.isArray(utmContent) ? utmContent[0] : utmContent
      }
    }

    const doSubmit = async () => {
      setFormLoading(true)
      try { 
        const submittedData = await fetchData({
          url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/non-login-donation`,
          method: 'POST',
          body: {
            ...generalData,
            ...campaignSpecificData
          },
          additionalHeaders: customHeaders
        })
        if (submittedData.data) {
          setOpenCodeCookie(submittedData.data[0].open_code, submittedData.data[0].id)

          const deeplinkRedirect = isUsingGopayAccount
            ? (
              submittedData.data[0]['gopay_verification_link_url']
                ? submittedData.data[0]['gopay_verification_link_url']
                : `${process.env.REACT_APP_GOGIVE_URL}/donation/status/${submittedData.data[0].id}${redirect_callback_params}&payment=gopay&dp_nav_stack=true`
            )
            : submittedData.data[0]['gopay_deeplink_redirect']

          setFormLoading(false)
          eventTracker('lanjutkan pembayaran contribute gogive')

          window.location = deeplinkRedirect

        } else {
          setFormLoading(false)
          setDonationFailed(true)
          setErrorMessage(submittedData.error)
        }
      } catch (error) {
        return window.location = `${process.env.REACT_APP_ERROR_URL}?from=${process.env.REACT_APP_GOGIVE_URL}/campaign/${props.match.params.shortUrl}` 
      }
    }

    if (isValid()) {
      doSubmit()
    }
  }

  const onValueChange = (values) => {
    eventTracker('field nominal contribute gogive')
    setAmount(values.floatValue)
  }

  const handleLinkSSO = () => {
    eventTracker('hubungkan sso contribute')
    return props.history.push(`/sso?redirectUrl=${encodeURIComponent(window.location.href)}`)
  }

  const handleLinkGopay = () => {
    eventTracker('hubungkan gopay tokenisation contribute')
    const stringifiedQuery = (!isEmpty(urlParams)) ? `&${stringify(urlParams)}` : ''
    const gopayParam = [...new Set(`ref=contribute&campaign=${props.match.params.shortUrl}${stringifiedQuery}`.split('&'))].join('&')

    return props.history.push(`/gopay-link?${gopayParam}`)
  }

  const handleUnlink = () => setUnlinkModal(!unlinkModal)
 
  if (loading || gopayLoading) return null

  return (
    <div className={styles.container}>
      {!userCookies && <PopupConnectSSO handleLinkSSO={handleLinkSSO} {...props} />}
      <Unlink
        handleUnlink={handleUnlink}
        unlinkModal={unlinkModal}
        submitUnlink={submitUnlink}
      />
      <FlashMessage
        colorType="orange"
        inProp={donationFailed}
        text={errorMessage}
        icon={
          <img
            src="https://assets.kitabisa.xyz/images/icon__alert--warning.svg"
            alt="icon-flash-message"
          />
        }
      />
      <ContributeNominal
        onValueChange={onValueChange}
        amount={amount}
      />
      <Divider />
      <div className={styles.gopaySection}>
        {
          userCookies ? (
            <div className={styles.gopayAccount}>
              <h4 className={styles['gopayAccount--name']}>{userCookies.name}</h4>
              <span className={styles['gopayAccount--phone']}>{userCookies.phone.split('-').join('')}</span>
            </div>
          ) : (
            <div className={styles.ssoToken}>
              <span className={styles.ssoCaption}>Hubungkan dengan akun Gojek</span>
              <button className={styles.btnSSO} onClick={handleLinkSSO}>Sambungkan</button>
            </div>
          )
        }
        <div  className={styles.ssoToken}>
          <img
            className={styles.gopayImg}
            src="/uploads/gopay-logo.svg"
            alt="gopay-icon"
          />
          {
            isLinkedGopay ? (
              <>
                <span className={styles.gopayBalance}>Rp {currencyFormatter(balance.substring(0, balance.length-3))}</span>
                <button className={styles.btnCancel} onClick={handleUnlink}>Batalkan</button>
              </>
            ) : (
              <button 
                className={userCookies ? styles.btnSSO : styles.btnToken}
                onClick={handleLinkGopay}
              >
                Sambungkan
              </button>
            )
          }
        </div>
        {(balance < amount) && isLinkedGopay && (<p className={styles.gopayInsufficient}>Saldo GoPaymu gak cukup, top up yuk~</p>)}
      </div>
      <div className={styles.section}>
        <SwitchToggle name="anonym" onChange={() => setAnonym(!anonym)} label="Sembunyikan nama saya (donasi anonim)"/>
        <SwitchToggle name="comment" onChange={() => setUseComment(!useComment)} label="Tulis pesan semangat"/>
        {
          (useComment) ? <Textarea maxLength="140" onChange={setComment} placeholder="Sumbang suara kamu di sini"/> : null
        }
      </div>
      <div className={styles.sectionDonationBtn}>
        <Button
          disabled={!isValid()}
          backgroundColor="#50B058"
          height="40px"
          fontSize="14px"
          margin="0 16px"
          onClick={() => {
            sendClickEventSdk()
            eventTracker('button donasi sekarang gogive')
            submitData()
          }}
        >
          {
            (formLoading) ?
              <span><img src={LoaderImg} alt="Submitting Data" /></span>
              :
              <span>LANJUT PEMBAYARAN</span>
          }
        </Button>
      </div>
    </div>
  );
};

export default ContributePage;
