import React from 'react'
import styles from './styles.module.scss'

const Layout = props => (
  <div className={styles.layout} {...props}>
    {props.children}
  </div>
)

export default Layout

