import React from 'react';
import { currencyFormatter } from '@3pp/utils'
import ImgDraft from '../assets/donation-draft.svg'
import styles from '../styles.module.scss';

const Failed = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles['wrapper-img']}>
        <img src={ImgDraft} alt='draft' />
      </div>
      <p className={styles.textTitle}>Pembayaran sedang diproses</p>
      <p className={styles.textSubtitle}>Donasi Sebesar</p>
      <span className={styles.textAmount}>Rp{currencyFormatter(props.amount)}</span>
    </div>
  );
}

export default Failed
