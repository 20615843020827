import React from 'react'
import SsoPage from 'components/SsoPage'

const Sso = (props) => {
  return (
    <SsoPage {...props} />
  )
}

export default Sso
