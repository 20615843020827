import React from 'react';
import { FacebookShareButton, WhatsappShareButton } from 'react-share'

import fbIcon from './assets/fb.svg'
import waIcon from './assets/wa.svg'
import Failed from './Failed';
import Success from './Success';
import Draft from './Draft';
import styles from './styles.module.scss';

const Donation = (props) => {
  const donationStatus = props.donation.status.status
  const fbShareUrl = `${props.donation.shareable_links.facebook}/?utm_source=facebook&utm_medium=socialsharing`
  const waShareUrl = `${props.donation.shareable_links.whatsapp}/?utm_source=whatsapp&utm_medium=socialsharing`
  const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1
  const queryParams = isAndroid ? decodeURIComponent(props.location.search) : props.location.search

  return (
    <div className={styles.donationLayout}>
      {
        donationStatus === 'VERIFIED' || donationStatus === 'PAID'
          ? <Success {...props}/>
          : donationStatus === 'DRAFT'
            ? <Draft {...props} />
            : <Failed {...props}/>
      }
      {
        donationStatus !== 'DRAFT' && (
          <section className={styles.share}>
            <p className={styles.share__info}>
              Bantu <strong> {props.donation.campaign.campaigner} </strong> mencapai target donasi
            </p>
            <>
              <div className={styles.shareButton}>
                <FacebookShareButton
                  url={fbShareUrl}
                  className={styles.fbShare}
                >
                  <span className={styles.shareWrapper}>
                    <img src={fbIcon} alt="wa-share" className={styles.shareImg}/>
                    <span className={styles.shareText}>Facebook</span>
                  </span>
                </FacebookShareButton>
                <WhatsappShareButton
                  url={waShareUrl}
                  className={styles.waShare}
                >
                  <span className={styles.shareWrapper}>
                    <img src={waIcon} alt="wa-share" className={styles.shareImg}/>
                    <span className={styles.shareText}>Whatsapp</span>
                  </span>
                </WhatsappShareButton>
              </div>
            </>

            <div className={styles.back}>
              <button
                onClick={() => {
                  props.sendClickEventSdk()
                  props.history.push(`/campaign/${props.donation.campaign.short_url}${queryParams}`)
                }}
                className={styles.back__btn}
              >
                KEMBALI KE HALAMAN GALANG DANA
              </button>
            </div>
          </section>
        )
      }
    </div>
  );
}

export default Donation
