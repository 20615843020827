var IMGIX_URL = 'https://img.staging.kitabisa.cc';
var IMAGE_URL = 'https://imgix.kitabisa.xyz';
export var getImgixUrl = function getImgixUrl(url) {
  var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '214';
  var split = url.split('/');
  var index = url.indexOf(IMAGE_URL);

  if (index === -1) {
    return url;
  } else {
    return "".concat(IMGIX_URL, "/").concat(split[5], "?ar=16:9&w=").concat(size, "&fit=crop&fm=pjpeg");
  }
};