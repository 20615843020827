const getAppInfo = async () => {
  return await window.gojek.getAppInfo()
}

const getUserInfo = async () => {
  return await window.gojek.getUserInfo();
}

const isGojekDevtools = process.env.REACT_APP_GOJEK_SDK_DEVTOOLS === 'true'

export {
  getAppInfo,
  getUserInfo,
  isGojekDevtools
}
