import Cookies from 'universal-cookie'
import sha1 from 'sha1'

const expirationDate = (days = 60) => {
  let expires = new Date()
  expires.setTime(+expires + days * 86400000)
  return expires
}

const checkCookie = req => {
  return (req && req.headers) ? new Cookies(req.headers.cookie) : new Cookies()
}

const getVisitorID = () => {
  const cookie = checkCookie()
  let visitorID = cookie.get('KTBS_VISITOR_ID')

  if(!visitorID) {
    const cookie = new Cookies()
    visitorID = `${new Date().getTime()}_${Math.floor(Math.random() * 10000)}`
    cookie.set('KTBS_VISITOR_ID', visitorID, {
      path: '/',
      maxAge: expirationDate(1)
    })
  }
  return visitorID
}

const setOpenCodeCookie = (val, id) => {
  const cookies = new Cookies()
  const cookieName = `open_code_donation_${id}`
  const domain = `.${(new URL(window.location.href)).hostname.split('.').slice(-2).join('.')}`
  cookies.set(cookieName, val, {
    path: '/',
    expires: expirationDate(3),
    domain,
  })
}

const getOpenCodeCookie = (id) => {
  const cookie = checkCookie()
  const openCode = cookie.get(`open_code_donation_${id}`)
  return openCode
}

/**
 *
 * @param {String} expName experiment name
 * @param {Number} numTreatments number of treatment
 */
const computeAssignmentForVisitorID = (expName, numTreatments) => {
  const visitorID = getVisitorID()
  const toHash = `${expName}_${visitorID}`

  // We take the first 12 character (out of 40)
  // otherwise the int overflows
  // const shaResult = sha1(toHash.substr(0, 12))
  const shaResult = sha1(toHash).substr(0, 12)
  const hash = parseInt(shaResult, 16)
  return hash % numTreatments
}

export { expirationDate, setOpenCodeCookie, getOpenCodeCookie, computeAssignmentForVisitorID }

