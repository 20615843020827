import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import crypto from 'crypto'

const fetchWording = ({ url, method = 'GET', data = {}}) => {
  let headers = null
  const timestamp =  dayjs().unix()
  const clientName = process.env.REACT_APP_CLIENT_NAME;
  const kuncenSecret = process.env.REACT_APP_KUNCEN_SECRET;
  const hmac = crypto.createHmac('sha256', `${kuncenSecret}`);
  hmac.update(`${clientName}${timestamp}`);
  const signature = hmac.digest('hex');

  const defaultHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
  }
  const additionalHeaders = {
    'X-Ktbs-Request-ID': uuidv4(),
    'X-Ktbs-Client-Name': process.env.REACT_APP_CLIENT_NAME,
    'X-Ktbs-Time': timestamp,
    'X-Ktbs-Signature': signature,
    'X-Ktbs-Api-Version':'v1.0.0',
    'X-Ktbs-Client-Version':'v1.0.0',
    'X-Ktbs-Platform-Name':'web'
  }
  headers = {...defaultHeaders, ...additionalHeaders}

  return axios({
    url,
    method,
    data,
    headers
  })
}

export default fetchWording
