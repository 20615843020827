import React from 'react'
import Divider from 'components/common/Divider'
import { Button } from '@3pp/ui-library'
import styles from './styles.module.scss'
import { eventTracker, isEmpty } from '@3pp/utils'
import { getAppInfo, getUserInfo, isGojekDevtools } from 'utils/gojekSdk'
import { stringify } from 'query-string'
import queryString from 'query-string'
import Cookies from 'universal-cookie';

const ContentFilterTrigger = ({
  activeContent,
  setActiveContent,
  ...props
}) => {
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  const cookies = new Cookies();
  const isSso = cookies.get('usr') ? true : false
  const sendEventSdk = () => {
    isGojekDevtools && window.gojek.toggleOnDevTool();
    const eventName = `Product List Viewed`
    const eventValue = {
      'Url': process.env.REACT_APP_GOGIVE_URL,
      'Source': 'utm_source=3pp_gojek',
      'Medium': `utm_medium=${queryParams.utm_medium}`,
      'Campaign': `utm_campaign=${queryParams.utm_campaign}`,
      'PartnerName': 'Kitabisa',
      'SSO': isSso
    }
    try {
      isGojekDevtools && window.gojek.console.log(
        `window.gojek.sendEvent(${eventName}, ${JSON.stringify(eventValue)})`
      );
      window.gojek.sendEvent(eventName, eventValue)
      getAppInfo()
      getUserInfo()
    } catch (error) {
      console.warn('Unsupported OS for gogive sdk when click category tab: ', error);
    }
  }
  return (
    <section className={styles.homeFilter}>
      <h3>Pilihan berbagi kebaikan</h3>
      <div className={styles.homeFilterActionContainer}>
        {activeContent.map(contentItem => {
          return (
            <div key={contentItem.id} className={styles.homeFilterAction}>
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  lineHeight: 1,
                  borderRadius: 15,
                  padding: '9px 17px',
                  border: contentItem.active ? '1px solid #50B058' : '1px solid #E6E6E6',
                  backgroundColor: contentItem.active ? '#50B058' : '#fff',
                  color: contentItem.active ? '#fff' : '#4A4A4A'
                }}
                fontSize="11px"
                label={contentItem.label}
                onClick={() => {
                  switch (contentItem.id) {
                    case 1:
                      eventTracker('category apa saja gogive ver 2')
                      sendEventSdk()
                      break;
                    case 2:
                      eventTracker('category ended soon gogive ver 2')
                      sendEventSdk()
                      break;

                    default:
                      eventTracker(`category ${contentItem.slug} gogive ver 2`)
                      sendEventSdk()
                      break;
                  }
                  setActiveContent({
                    id: contentItem.id,
                    label: contentItem.label,
                    active: true
                  })
                  props.history.push(`${contentItem.id !== 1 ? `/home/${contentItem.slug}${stringifiedQuery}` : `/home${stringifiedQuery}`}`)
                }}
              />
            </div>
          )
        })}
      </div>
      <Divider />
    </section>
  )
}

export default ContentFilterTrigger
