import React from 'react'
import Slider from 'react-slick'
import ContentLoader from 'react-content-loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import queryString from 'query-string'
import Cookies from 'universal-cookie';
import { eventTracker, isAndroid } from '@3pp/utils'
import { getAppInfo, getUserInfo, isGojekDevtools } from 'utils/gojekSdk'

import styles from './styles.module.scss'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

const Carousel = props => {
  const cookies = new Cookies();
  let queryParams
  if (isAndroid) {
    queryParams = queryString.parse(decodeURIComponent(props.location.search))
  } else {
    queryParams = queryString.parse(props.location.search)
  }
  const Loader = () => (
    <ContentLoader
      key="carousel"
      uniquekey="carousel"
      speed={2}
      width={480}
      height={253}
      viewBox="0 0 480 253"
      backgroundcolor="#f3f3f3"
      foregroundcolor="#ecebeb"
    >
      <rect x="10" y="1" rx="5" ry="5" width="450" height="250" />
      <rect x="470" y="1" rx="5" ry="5" width="18" height="250" />
    </ContentLoader>
  )
  const settings = {
    dots: true,
    arrows: false,
    lazyLoad: false,
    slideToShow: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
  }
  const isSso = cookies.get('usr') ? true : false
  const sendEventSdk = (banner) => {
    isGojekDevtools && window.gojek.toggleOnDevTool();
    const eventName = 'Homepage Button Clicked'
    const eventValue = {
      'url': `${process.env.REACT_APP_GOGIVE_URL}/campaign`,
      'Source': 'utm_source=3pp_gojek',
      'Medium': `utm_medium=${queryParams.utm_medium}`,
      'Campaign': `utm_campaign=${queryParams.utm_campaign}`,
      'PartnerName': 'Kitabisa',
      'Deeplink': 'gogive.kitabisa.com/campaign',
      'ImageUrl': banner.image,
      'ProductID': banner.id,
      'ProductName': banner.url,
      'SSO': isSso,
      'ActivityName': 'donasi'
    }
    try {
      isGojekDevtools && window.gojek.console.log(
        `window.gojek.sendEvent(${eventName}, ${JSON.stringify(eventValue)})`
      );
      window.gojek.sendEvent(eventName, eventValue)
      getAppInfo();
      getUserInfo();
    } catch (error) {
      console.warn('Unsupported OS for gogive sdk when click slider banner: ', error)
    }
  }

  return (
    <div className={styles.carousel}>
      {
        (props.banners !== null) ?
          <Slider {...settings}>
            {props.banners.map((banner, index) => {
              return(
                <a
                  onClick={() => {
                    let nextSlider =''
                    if(index > 0){
                      nextSlider = ` ${index + 1} ver2`
                    }
                    eventTracker(`banner slider gogive${nextSlider}`)
                    sendEventSdk(banner)
                  }}
                  title={banner.url}
                  key={banner.id}
                  href={banner.url}
                >
                  <LazyLoadImage
                    alt={banner.url}
                    width={450}
                    src={`${banner.image}?auto=format,compress`}
                  />
                </a>
              )
            })}
          </Slider>
          :
          <Loader />
      }
    </div>
  )
}

export default Carousel
