import React from 'react'
import SearchPage from 'components/SearchPage'
import { fetchData } from '@3pp/utils'
import customHeaders from 'utils/customHeaders'

const Search = (props) => {
  const querySearch = async (keyword: string) => {
    const fetchQuery = await fetchData({
      url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?keyword=${keyword.split(/[ ,]+/).join(',')}`,
      method: 'GET',
      additionalHeaders: customHeaders,
    })
    return fetchQuery.data[0]
  }

  return (
    <SearchPage
      querySearch={querySearch}
      {...props}
    />
  )
}

export default Search
