import React from 'react'
import ContentLoader from 'react-content-loader'

const Loader = props => {
  return (
    <ContentLoader
      height={props.height}
      width={props.width}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}
    >
      {props.children}
    </ContentLoader>
  )
}

const MiniListLoader = ({ height = 85, width = 380, uniquekey = null}) => (
  <React.Fragment>
    {Array(5)
      .fill('')
      .map((e, i) => (
        <ContentLoader
          uniquekey={`${uniquekey}-${i}`}
          key={`${uniquekey}-${i}`}
          style={{ opacity: Number(2 / i).toFixed(1) }}
          height={height}
          width={width}
        >
          <rect x="20" y="20" rx="5" ry="5" width="64" height="64" />
          <rect x="105" y="20" rx="5" ry="5" width="250" height="12" />
          <rect x="105" y="40" rx="5" ry="5" width="180" height="12" />
          <rect x="105" y="60" rx="5" ry="5" width="125" height="12" />
        </ContentLoader>
      ))}
  </React.Fragment>
)

const MiniSliderListLoader = () => (
  <ContentLoader
    uniquekey="minilistLoaderKey"
    key="minilistLoader"
    height={250}
    width={480}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="18" rx="0" ry="0" width="155" height="201" />
    <rect x="165" y="17" rx="0" ry="0" width="155" height="204" />
    <rect x="330" y="18" rx="0" ry="0" width="155" height="204" />
  </ContentLoader>
)

const CampaignStoryLoader = () => (
  <ContentLoader
    uniquekey="campaignStoryLoader"
    style={{ padding: '0 16px', maxWidth: 480, width: '100%', height: 'auto'}}
    viewBox="0 0 400 160" height={160} width={400} speed={1}>
    <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
    <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
    <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
    <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
    <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
    <rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
  </ContentLoader>
)

const CampaignInfoLoader = () => (
  <ContentLoader
    uniquekey="campaignInfoLoader"
    style={{ padding: '0 16px', maxWidth: 480, width: '100%', height: 'auto'}}
    speed={2}
    width={448}
    height={205}
    viewBox="0 0 448 205"
    backgroundcolor="#f3f3f3"
    foregroundcolor="#ecebeb"
  >
    <rect x="1" y="0" rx="3" ry="3" width="440" height="24" />
    <rect x="2" y="31" rx="3" ry="3" width="410" height="24" />
    <rect x="2" y="65" rx="3" ry="3" width="448" height="5" />
    <rect x="367" y="79" rx="3" ry="3" width="81" height="24" />
    <rect x="2" y="79" rx="3" ry="3" width="81" height="24" />
    <rect x="2" y="110" rx="0" ry="0" width="448" height="84" />
  </ContentLoader>
)

const CoverImageLoader = () => (
  <div>
    <ContentLoader
      style={{ maxWidth: 480, width: '100%', height: 'auto'}}
      speed={2}
      width={375}
      height={211}
      viewBox="0 0 375 211"
      backgroundcolor="#f3f3f3"
      foregroundcolor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="375" height="211" />
    </ContentLoader>
  </div>
)

export {
  Loader as default,
  CoverImageLoader,
  CampaignInfoLoader,
  MiniListLoader,
  CampaignStoryLoader,
  MiniSliderListLoader
}
