import React from 'react'
import SearchInput from '../SearchInput'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { isEmpty } from '@3pp/utils'
import { stringify } from 'query-string'
import styles from './styles.module.scss'

const Header = props => {
  if (!props) return null
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  return (
    <header className={styles.siteHeader}>
      <Link to={`/${stringifiedQuery}`} title="GO-GIVE">
        <img src="/images/logo.svg" className={styles.siteHeader__logo} alt="GO-GIVE" />
      </Link>
      <SearchInput {...props} />
    </header>
  )
}

export default Header
