import React from 'react'

const Divider = (props) => {
  return (
    <hr
      style={
        {
          borderTop: '1px solid #E3E3E3',
          margin: props.margin ? props.margin : '20px -0' ,
          borderBottom: 'none'
        }}
    />
  )
}

export default Divider
