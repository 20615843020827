import React from 'react'
import styles from './styles.module.scss'

interface Props {
  id: number;
  title: string;
  type: string;
  content: string;
  created: number;
  published?: number;
  short_url: string;
  disbursement_detail: {
    dest_bank_name: string;
    dest_bank_account_holder: string;
    dest_bank_account_number: string;
  };
  publisher: {
    name: string;
    avatar: string;
    is_verified: boolean;
  };
}
const CampaignUpdateCard: React.FC<Props> = (props) => {
  return (
    <div className={`${styles['campaign-update-card']} ${props.type === 'DISBURSEMENT' ? styles.disburse : ''}`}>
      <div className={styles['campaign-update-card__publisher']}>
        <div className={styles['campaign-update-card__publisher-info']}>
          <img className={styles['campaign-update-card__publisher-avatar']} src={props.publisher.avatar} alt={props.publisher.name} />
          <div className={styles['campaign-update-card__publisher-info-detail']}>
            <span className={styles['campaign-update-card__publisher-name']}>{props.publisher.name}</span>
            <span className={styles['campaign-update-card__publisher-date']}>
              {props.created}
            </span>
          </div>
        </div>
        <div className={styles['campaign-update-card__content']}>
          <h4>{props.title}</h4>
          {
            props.type === 'DISBURSEMENT' && (
              <p>
              ke rekening {props.disbursement_detail.dest_bank_name} {props.disbursement_detail.dest_bank_account_number} a/n {props.disbursement_detail.dest_bank_account_holder}
              </p>
            )
          }
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      </div>
    </div>
  )
}
export default CampaignUpdateCard
