import React from 'react'
import { eventTracker, isEmpty } from '@3pp/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { stringify } from 'query-string'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import styles from './styles.module.scss'

const SearchInput = props => {
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  return (
    <div className={styles.searchInput}>
      <Link to={`/search${stringifiedQuery}`} onClick={() => eventTracker('search button gogive ver 2')} className={styles.searchInput__input}>
        <FontAwesomeIcon
          icon={faSearch}
        />
        <span>
          Mau bantu siapa hari ini?
        </span>
      </Link>
    </div>
  )
}

export default SearchInput
