import React from 'react'
import GopayLink from 'components/Gopay/Link'

const Gopay = (props) => {
  return (
    <GopayLink {...props}/>
  )
}

export default Gopay
