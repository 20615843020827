/* eslint-disable @typescript-eslint/no-explicit-any */
import 'isomorphic-unfetch';
export var fetchData = function fetchData(props) {
  var defaultHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json'
  };
  var data = {
    body: JSON.stringify(props.body)
  };
  var params = {
    method: props.method,
    headers: props.additionalHeaders ? Object.assign(Object.assign({}, defaultHeaders), props.additionalHeaders) : defaultHeaders
  };

  if (props.method === 'POST' && props.body !== null) {
    params = Object.assign(Object.assign({}, params), data);
  }

  return fetch(props.url, Object.assign({}, params)).then(function (response) {
    return response;
  }).then(function (r) {
    return r.json();
  });
};
fetchData.defaultProps = {
  method: 'GET'
};