import * as Sentry from '@sentry/browser';
import customHeaders from 'utils/customHeaders'
import { fetchData } from '@3pp/utils'
import { expirationDate } from 'utils/computeAssignmentForVisitorId'

const setUserData = async ({ auth_code, state, cookies }) => {
  try {
    const { data } = await fetchData({
      method: 'POST',
      url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/gojek/token`,
      body: {
        auth_code,
        state,
        grant_type: "authorization_code"
      },
      additionalHeaders: customHeaders
    })
    cookies.set('usr', data[0], {
      path: '/',
      expires: expirationDate(365)
    });
    const redirectUrl = data[0].redirect_url
    if(!redirectUrl.includes('/home') && redirectUrl) {
      return window.location =  decodeURIComponent(redirectUrl)
    }
  } catch (error) {
    Sentry.setExtra('error', error)
    Sentry.captureException(error)
    throw error
  }
}

export default setUserData
