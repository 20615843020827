import React, { useState, useEffect, Suspense} from 'react'
import Cookies from 'universal-cookie';

import Header from 'components/common/Header'
import Carousel from 'components/common/Carousel'
import GopayAccount from 'components/Gopay/Account'

import { fetchData } from '@3pp/utils'
import isGopayDisabled from 'utils/isGopayDisabled'
import customHeaders from 'utils/customHeaders'
import { expirationDate } from 'utils/computeAssignmentForVisitorId'

import ContentFilterTrigger from './ContentFilterTrigger'
import styles from './styles.module.scss'

const SectionAll = React.lazy(() => import('./SectionAll'))
const SectionEndingSoon = React.lazy(() => import('./SectionEndingSoon'))
const SectionMedic = React.lazy(() => import('./SectionMedic'))
const SectionEducation = React.lazy(() => import('./SectionEducation'))
const SectionZakat = React.lazy(() => import('./SectionZakat'))

const HomeComponent = (props) => {
  const [activeContent, setActiveContent] = useState([
    {
      id: 1,
      active: true,
      slug: 'all',
      label: props.tab[0]
    },
    {
      id: 2,
      active: false,
      slug: 'endingsoon',
      label:  props.tab[1]
    },
    {
      id: 3,
      active: false,
      slug: 'medical',
      label: props.tab[2]
    },
    {
      id: 4,
      active: false,
      slug: 'zakat',
      label: props.tab[3]
    },
    {
      id: 5,
      active: false,
      slug: 'education',
      label: props.tab[4]
    },
  ])
  const cookies = new Cookies();
  const isLinkedGopay = cookies.get('gopay_id')

  useEffect(() => {
    if(props.match.params.slug) {
      setActiveContent(activeContent.map(item => item.slug === props.match.params.slug ? {...item, active: true} : {...item, active: false}))
    }

    const fetchGopayAccount = async () => {
      const { data } = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/gojek/account/${isLinkedGopay}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      })
      cookies.set('gopay_status', data[0].account_status, {
        path: '/',
        expires: expirationDate(365)
      })
    }

    if(isLinkedGopay) {
      fetchGopayAccount()
    }
    //eslint-disable-next-line
  },[props.match.params.content, isLinkedGopay])

  const renderContent = () => {
    switch (activeContent.find(content => content.active).id) {
      case 2:
        return (<Suspense fallback={<div/>}><SectionEndingSoon {...props} /></Suspense>)
      case 3:
        return (<Suspense fallback={<div/>}><SectionMedic {...props}/></Suspense>)
      case 4:
        return (<Suspense fallback={<div/>}><SectionZakat {...props}/></Suspense>)
      case 5:
        return (<Suspense fallback={<div/>}><SectionEducation {...props}/></Suspense>)
      default:
        return (<Suspense fallback={<div/>}><SectionAll {...props} /></Suspense>)
    }
  }
  return (
    <div className={styles.home}>
      <Header {...props} />
      {
        !isGopayDisabled && (
          <GopayAccount {...props} />
        )
      }
      <Carousel banners={props.banners} {...props} />
      <ContentFilterTrigger
        activeContent={activeContent}
        setActiveContent={(value) => {
          setActiveContent(activeContent.map(item => item.id === value.id ? {...item, active: true} : {...item, active: false}))
        }}
        {...props}
      />
      <div className={styles.content}>
        {renderContent()}
      </div>
    </div>
  )
}

export default HomeComponent
