/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react'
import Layout from 'components/common/Layout'
import HomeComponent from 'components/HomeComponent'
import customHeaders from 'utils/customHeaders'
import queryString from 'query-string'
import Cookies from 'universal-cookie';
import * as Sentry from '@sentry/browser';
import { fetchData, isAndroid } from '@3pp/utils'

import fetchWording from 'utils/fetchWording'
import setUserData from 'utils/setUserData'
import { getAppInfo, getUserInfo, isGojekDevtools } from 'utils/gojekSdk'


const Home = props => {
  const [banners, setBanners] = useState(null)
  const [carouselCampaign, setCarouselCampaign] = useState(null)
  const [listCampaign, setListCampaign] = useState(null)
  const [endingSoon, setEndingSoon] = useState(null)
  const [content, setContent] = useState(null)
  const [tab, setTab] = useState(null)
  const cookies = new Cookies();
  const queryParams = queryString.parse(isAndroid ? decodeURIComponent(props.location.search) : props.location.search)

  const auth_code = queryParams.code || null
  const state = queryParams.state || null

  useEffect(() => {
    if (auth_code !== null && state !== null) {
      setUserData({auth_code, state, cookies})
    }
  }, [auth_code, cookies, state])
  useEffect(() => {
    const fetchBanner = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/banners`,
        method: 'GET',
        additionalHeaders: customHeaders,
      }).then(data => {
        setBanners(data.data)
      }).catch(error => {
        Sentry.setExtra('error', error)
        Sentry.captureException(error)
        throw error
      })
    }
    const fetchCampaign = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_CAMPAIGN_PARTNER_V2}`,
        method: 'GET',
        additionalHeaders: customHeaders
      }).then(data => {
        setCarouselCampaign(data.data[0].data)
      }).catch(error => {
        Sentry.setExtra('error', error)
        Sentry.captureException(error)
        throw error
      })
    }
    const fetchListCampaign = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_CAMPAIGN_ICD_PARTNER_V2}`,
        method: 'GET',
        additionalHeaders: customHeaders
      }).then(data => {
        setListCampaign(data.data[0].data)
      }).catch(error => {
        Sentry.setExtra('error', error)
        Sentry.captureException(error)
        throw error
      })
    }
    const fetchEndingSoon = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_SECTION_ENDING_SOON_V2}`,
        method: 'GET',
        additionalHeaders: customHeaders
      }).then(data => {
        setEndingSoon(data.data[0].data)
      }).catch(error => {
        Sentry.setExtra('error', error)
        Sentry.captureException(error)
        throw error
      })
    }
    const getWording = async() => {
      try {
        const {data} = await fetchWording({
          url: `${process.env.REACT_APP_GENI_URL}/3pp/key/section-title`,
          method: 'GET',
        })
        setContent(data.data[0].value.content);
      } catch(err) {
        console.error(err)
      }
    }
    const getTabbing = async() => {
      try {
        const {data} = await fetchWording({
          url: `${process.env.REACT_APP_GENI_URL}/3pp/key/tabbing`,
          method: 'GET',
        })
        setTab(data.data[0].value.tabs);
      } catch(err) {
        console.error('err', err)
        Sentry.captureException(err);
        Sentry.captureMessage("Something went wrong");
      }
    }
    const isSso = cookies.get('usr') ? true : false
    const sendEventSdk = () => {
      isGojekDevtools && window.gojek.toggleOnDevTool();
      const eventName = 'Homepage Viewed'
      const eventValue = {
        'Url': process.env.REACT_APP_GOGIVE_URL,
        'Source': 'utm_source=3pp_gojek',
        'Medium': `utm_medium=${queryParams.utm_medium}`,
        'Campaign': `utm_campaign=${queryParams.utm_campaign}`,
        'PartnerName': 'Kitabisa',
        'SSO': isSso,
      }
      isGojekDevtools && window.gojek.console.log(
        `window.gojek.sendEvent(${eventName}, ${JSON.stringify(eventValue)})`
      );
      window.gojek.sendEvent(eventName, eventValue)
    }
    try {
      sendEventSdk();
      getAppInfo();
      getUserInfo();
    } catch (error) {
      console.warn('Unsupported OS for gogive sdk when Homepage Viewed: ', error);
    }
    getTabbing()
    getWording()
    fetchListCampaign()
    fetchBanner()
    fetchCampaign()
    fetchEndingSoon()
    //eslint-disable-next-line
  }, [])
  if(content === null) return null
  if(tab === null) return null

  return (
    <Layout>
      <HomeComponent
        banners={banners}
        carouselCampaign={carouselCampaign}
        endingSoon={endingSoon}
        listCampaign={listCampaign}
        content={content}
        tab={tab}
        {...props}
      />
    </Layout>
  )
}

export default Home
