import React, { useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import Card from 'components/common/Card'
import { stringify } from 'query-string'
import queryString from 'query-string'
import { MiniListLoader } from 'components/common/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from '@3pp/utils'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import styles from './styles.module.scss'

interface State {
  title: string;
  short_url: string;
  id: number;
  image: string;
  donation_percentage: number;
  donation_received: number;
  campaigner?: string;
  campaigner_is_verified?: boolean;
  campaigner_badge?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchPage: React.SFC<{ initial?: State[]}> = (props: any, { initial = []}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [data, setData] = useState(initial)
  const [notFound, setNotFound] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''

  const handleQuery = (keyword) => {
    props.querySearch(keyword)
      .then(result => {
        if (result.data.length > 0) {
          setData(result.data)
          setNotFound(false)
          setIsLoading(false)
        } else {
          setNotFound(true)
          setIsLoading(false)
        }
      })
  }
  const debounced = debounce(keyword => handleQuery(keyword), 500)

  useEffect(() => {
    if (queryParams.q) {
      handleQuery(queryParams.q)
      setSearchQuery(queryParams.q.toString())
    }
    //eslint-disable-next-line
  }, [])


  const handleKeyword = (e: { target: HTMLInputElement }): void =>  {
    if (e.target.value.length <= 2) return null
    return debounced(e.target.value)
  }

  const renderContent = (): JSX.Element | JSX.Element[] => {
    if (notFound) {
      return (
        <p className={styles['search-page__results-not-found']}>
          Campaign yang anda cari tidak dapat ditemukan
        </p>
      )
    }
    if (isLoading) return <MiniListLoader />
    return (
      <>
        {
          (data.length > 0) ?
            <div className={styles['search-page__title-header']}>
              <span>Hasil Pencarian</span>
              <a href="https://kitabisa.com" title="Kitabisa">LIHAT SEMUA</a>
            </div>
            : null
        }
        {data.map((item: {
          campaigner: string;
          campaigner_is_verified: boolean;
          campaigner_badge: string;
          title: string;
          short_url: string;
          id: number;
          image: string;
          donation_percentage: number;
          donation_received: number;
        }) => {
          return (
            <Card
              onClick={(): Promise<boolean> => props.history.push(`/campaign/${item.short_url}${stringifiedQuery}`)}
              campaigner={{
                name: item.campaigner,
                verified: item.campaigner_is_verified,
                badge: item.campaigner_badge
              }}
              gtmEvent="list campaign card gogive ver 2"
              title={item.title}
              key={item.id}
              size="small--column"
              image={item.image}
              donationCollected={item.donation_received}
              noButton={false}
            />
          )
        })}
      </>
    )
  }
  return (
    <div className={styles['search-page']}>
      <div className={styles['search-page__input-container']}>
        <div className={styles['search-page__wrapper']}>
          <button
            className={styles['search-page__close-btn']} onClick={(): Promise<boolean> => props.history.push(`/${stringifiedQuery}`)}>
            <FontAwesomeIcon  icon={faTimes} />
          </button>
          <input
            autoFocus
            defaultValue={searchQuery}
            onChange={handleKeyword}
            className={styles['search-page__input']}
            placeholder="Cari Penggalangan" type="text"
          />
        </div>
      </div>
      <div className={styles['search-page__results']}>
        <div className={styles['search-page__results-wrapper']}>
          {renderContent()}
        </div>
      </div>
    </div>
  )
}

export default SearchPage
