import React from 'react';
import { currencyFormatter } from '@3pp/utils'
import ImgSuccess from '../assets/donation-success.svg'
import styles from '../styles.module.scss';

const Success = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles['wrapper-img']}>
        <img src={ImgSuccess} alt='success' />
      </div>
      <p className={styles.textTitle}>Pembayaran Berhasil, Donasimu <br/> Sudah Tersalurkan</p>
      <p className={styles.textSubtitle}>Donasi Sebesar</p>
      <span className={styles.textAmount}>Rp{currencyFormatter(props.amount)}</span>
      <div className={styles.customDivider}> </div>
    </div>
  );
}

export default Success
