import React from 'react'
import ContributePage from 'components/ContributePage'

const Contribute = (props) => {
  return (
    <ContributePage {...props} />
  )
}

export default Contribute
