import React from 'react'
import CampaignDetailUpdate from 'components/CampaignDetailUpdate'

const CampaignUpdate = (props) => {
  return (
    <CampaignDetailUpdate {...props} />
  )
}

export default CampaignUpdate
