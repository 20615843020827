import React from 'react'
import { currencyFormatter, eventTracker } from '@3pp/utils'
import { Button } from '@3pp/ui-library'
import styles from './styles.module.scss'

const Card = ({
  gtmEvent,
  title,
  image,
  donationCollected=0,
  size='small',
  onClick=() => null,
  campaigner={
    name: '',
    verified: false,
    badge: 'https://assets.kitabisa.cc/images/icons/icon__verified-user.svg'

  },
  noButton
}) => {
  return (
    <div onClick={() => {
      onClick()
      eventTracker(gtmEvent)
    }} className={styles[`card-${size}`]}>
      <div className={styles[`card-${size}__image-container`]}>
        <img className={styles[`card-${size}__image`]} src={image} alt={title} />
      </div>
      <div className={styles[`card-${size}__meta`]}>
        <div className={styles[`card-${size}__meta-info`]}>
          <span className={styles[`card-${size}__title`]}>
            {title}
          </span>
          <span className={styles[`card-${size}__donation-collected`]}>
            Rp. {currencyFormatter(donationCollected)}
          </span>
          <div className={styles[`card-${size}__campaigner`]}>
            <span>{campaigner.name}</span>
            {
              (campaigner.verified) ?
                <img src={campaigner.badge} alt="Verified" />
                : null
            }
          </div>
        </div>
        {
          noButton
            ? ''
            : (
              <div className={styles[`card-${size}__action`]}>
                <Button
                  key="donasi"
                  backgroundColor="#50B058"
                  label="DONASI"
                />
              </div>
            )
        }
      </div>
    </div>
  )
}

export default Card
