import React from 'react'
import dayjs from 'dayjs'
import InfiniteScroll from 'react-infinite-scroller'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useFetchList from 'utils/useFetchList'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import CampaignUpdateCard from 'components/common/CampaignUpdateCard'
import styles from './styles.module.scss'

const CampaignDetailUpdate = (props) => {
  // eslint-disable-next-line
  const [nextUrl,hasMore, fetchList, lists, isLoading] = useFetchList(
    `${process.env.REACT_APP_CORE_API}/campaigns/${props.match.params.id}/updates?`
  )

  if (isLoading || !lists) return null
  return (
    <div className={styles['campaign-detail-update']}>
      <div className={styles['campaign-detail-update__header']}>
        <div className={styles['campaign-detail-update__header-wrapper']}>
          <FontAwesomeIcon onClick={() => props.history.goBack()} icon={faArrowLeft} />
          <h3 className={styles['campaign-detail-update__header-title']}>Kabar terbaru</h3>
        </div>
      </div>
      <div className={styles['campaign-detail-update__wrapper']}>
        {lists.length > 0 ? (
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchList}
            hasMore={nextUrl ? true : false}
            loader={<div className={styles['donors__placeholder']} key={0} />}
            useWindow
            initialLoad={false}
          >
            {lists.map(dataItem => {
              return (
                <div key={dataItem.update.id} style={{ marginBottom: 8 }}>
                  <CampaignUpdateCard
                    key={dataItem.update.id}
                    id={dataItem.update.id}
                    title={dataItem.update.title}
                    publisher={dataItem.publisher}
                    disbursement_detail={dataItem.update.disbursement_detail}
                    short_url={dataItem.update.short_url}
                    created={dayjs.unix(dataItem.update.created).format('DD MMM YYYY')}
                    content={dataItem.update.content}
                    type={dataItem.update.type}
                  />
                </div>
              )
            })}
          </InfiniteScroll>
        ) : null }
      </div>
    </div>
  )
}

export default CampaignDetailUpdate
