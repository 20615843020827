import React, { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { fetchData, isAndroid } from '@3pp/utils'
import queryString from 'query-string'

import * as Sentry from '@sentry/browser';
import customHeaders from 'utils/customHeaders'
import styles from './styles.module.scss'

const SsoPage = (props) => {
  const cookies = new Cookies()
  const userCookie = cookies.get('usr') || null
  const queryParams = queryString.parse(isAndroid ? decodeURIComponent(props.location.search) : props.location.search)

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const eligibleForSSO = (
      userCookie === null && isIOS
    ) || (
      !isIOS && navigator.userAgent.indexOf('Gojek') > 0
    );
    if (eligibleForSSO) {
      const getGojekAuth = async () => {
        try {
          const { data } = await fetchData({
            method: 'GET',
            url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/gojek?redirect_url=${encodeURIComponent(queryParams.redirectUrl) || ''}`,
            additionalHeaders: customHeaders
          })
          if (data[0].redirect_url) {
            return window.location = data[0].redirect_url
          }
        } catch(error) {
          Sentry.setExtra('error', error)
          Sentry.captureException(error)
          throw error
        }
      }
      getGojekAuth()
    } else {
      return props.history.push('/')
    }
    //eslint-disable-next-line
  }, [userCookie])
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h3 className={styles.title}>Hey, jangan tinggalin<br />kami dong 😞</h3>
        <p className={styles.subtitle}>Mohon tunggu sejenak & kamu bisa <br />melanjutkan pengalaman berbagi kebaikan di GoGive</p>
      </div>
    </div>
  )
}

export default SsoPage
