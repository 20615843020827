import React, { Suspense } from 'react'
import {
  CampaignStoryLoader,
  CoverImageLoader,
  CampaignInfoLoader
} from 'components/common/Loader'
const CampaignDetail = React.lazy(() => import('components/CampaignDetail'))

const Campaign = (props) => {
  return (
    <Suspense fallback={
      <section style={{ maxWidth: 480, margin: '0 auto', width: '100%'}}>
        <CoverImageLoader />
        <CampaignInfoLoader />
        <CampaignStoryLoader />
      </section>
    }>
      <CampaignDetail {...props} />
    </Suspense>
  )
}

export default Campaign
