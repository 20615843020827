import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import queryString from 'query-string'
import Donation from 'components/Donation'
import { fetchData } from '@3pp/utils'
import { getAppInfo, getUserInfo, isGojekDevtools } from 'utils/gojekSdk'
import { getOpenCodeCookie } from 'utils/computeAssignmentForVisitorId'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gojek: any;
  }
}

const DonationPage = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [amount, setAmount] = useState(null)
  const [donation, setDonation] = useState(null)

  const orderId = props.match.params.orderId
  const cookies = new Cookies()
  const isSso = cookies.get('usr') ? true : false
  const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1
  const queryParams = queryString.parse(isAndroid ? decodeURIComponent(props.location.search) : props.location.search)

  useEffect(() => {
    let donationStatus: string

    const fetchDonation = async () => {
      try {
        const openCode = getOpenCodeCookie(orderId)
        const getDonation = await fetchData({
          url: `${process.env.REACT_APP_CORE_API}/mix_donations/${orderId}?open_code=${openCode}`,
        })
        setAmount(getDonation.data[0].invoice.total_invoiced)
        setDonation(getDonation.data[0].donations[0])
        donationStatus = getDonation.data[0].donations[0].status.status
      } catch (error) {
        return window.location.href = `${process.env.REACT_APP_ERROR_URL}?from=${`${process.env.REACT_APP_GOGIVE_URL}/campaign/${queryParams.utm_campaign}`}` 
      }
    }

    const interval = setInterval(() => {
      if (donationStatus === 'DRAFT') {
        fetchDonation()
      } else {
        clearInterval(interval)
      }
    }, 5000)

    const sendEventSdk = () => {
      isGojekDevtools && window.gojek.toggleOnDevTool();
      const eventName = 'Payment Completed Page Viewed'
      const eventValue = {
        'Url': `${process.env.REACT_APP_GOGIVE_URL}/campaign/${queryParams.utm_campaign}`,
        'Source': `utm_source=3pp_gojek`,
        'Medium': `utm_medium=${queryParams.utm_medium}`,
        'Campaign': `utm_campaign=${queryParams.utm_campaign}`,
        'PartnerName': 'Kitabisa',
        'Deeplink': 'gogive.kitabisa.com/campaign',
        'ProductID': queryParams.utm_campaign,
        'PaymentMethod': 'gopay',
        'ProductName': queryParams.category,
        'ActivityName': 'Pembayaran berhasil, donasimu sudah tersalurkan',
        'Transaction ID': orderId,
        'SSO': isSso,
      }
      isGojekDevtools && window.gojek.console.log(
        `window.gojek.sendEvent(${eventName}, ${JSON.stringify(eventValue)})`
      );
      window.gojek.sendEvent(eventName, eventValue)
    }
    try {
      sendEventSdk();
      getAppInfo();
      getUserInfo();
    } catch (error) {
      console.warn('Unsupported OS for gogive sdk when Payment Completed Page Viewed: ', error);
    }

    fetchDonation()

    //eslint-disable-next-line
  }, [])

  const sendClickEventSdk = () => {
    isGojekDevtools && window.gojek.toggleOnDevTool();
    const eventName = 'Payment Completed Button Clicked'
    const eventValue = {
      'url': `${process.env.REACT_APP_GOGIVE_URL}/campaign/${queryParams.utm_campaign}`,
      'Source': `utm_source=3pp_gojek`,
      'Medium': `utm_medium=${queryParams.utm_medium}`,
      'Campaign': `utm_campaign=${queryParams.utm_campaign}`,
      'PartnerName': 'Kitabisa',
      'Deeplink': 'gogive.kitabisa.com/campaign',
      'PaymentMethod': 'gopay',
      'ProductID': queryParams.utm_campaign,
      'ProductName': queryParams.category,
      'ActivityName': 'kembali ke halaman galang dana',
      'SSO': isSso
    }
    try {
      isGojekDevtools && window.gojek.console.log(
        `window.gojek.sendEvent(${eventName}, ${JSON.stringify(eventValue)})`
      );
      window.gojek.sendEvent(eventName, eventValue)
    } catch (error) {
      console.warn('Unsupported OS for gogive sdk when Payment Button Clicked: ', error);
    }
  }

  if (amount === null) return null
  if (donation === null) return null

  return (
    <Donation
      {...props}
      amount={amount}
      donation={donation}
      sendClickEventSdk={() => sendClickEventSdk()}
    />
  )
}

export default DonationPage
