import React, { useState }  from 'react'
import ReactModal from 'react-modal'

import styles from './styles.module.scss'

const PopupConnectSSO = (props) => {

  const [isModal, setModal] = useState(true)
  const handleOpenModal = () => {
    setModal(!isModal)
  }
  const onClick = () => {
    setModal(false)
    return props.handleLinkSSO()
  }

  return (
    <div>
      <ReactModal
        isOpen={isModal}
        contentLabel="onRequestClose"
        onRequestClose={handleOpenModal}
        className="Modal-contribute"
        overlayClassName="Overlay-contribute"
        ariaHideApp={false}
      >
        <div className={styles.imgTop}>
          <h2>
            Akun Gojek
          </h2>
        </div>
        <div className={styles.bottom}>
          <p className={styles[`bottom-title`]}>
            Hubungkan akun Gojekmu di GoGive sekali saja untuk kemudahan berdonasi berkali-kali
          </p>
          <button className={styles[`bottom-btn`]} onClick={onClick}>
            Hubungkan Sekarang
          </button>
        </div>
      </ReactModal>
    </div>
  )
}

export default PopupConnectSSO
